.container {
    margin: 0 auto;
    @media screen and (min-width: 320px) {
      max-width: 320px;
      padding-right: 20px;
      padding-left: 20px;
    }
  
  @media screen and (min-width: 768px) {
      max-width: 768px;
      padding-right: 75px;
      padding-left: 75px;
    }
  
    @media screen and (min-width: 1200px) {
      max-width: 1200px;
      padding-right: 75px;
      padding-left: 75px;
    }
  }