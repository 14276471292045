.section-salon_list {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding-bottom: 30px;
}

.section-salon_list-plus {
    margin: 20px 0;
    & li {
        margin-top: 10px;
    }
}

@media screen and (min-width: 1200px) {
    .section-salon_list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}