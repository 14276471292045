body {
  color: #212121;
  font-family: Montserrat, sans-serif;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.icon {
  fill: var(--main-color);
}

.hidden {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.section {
  color: #212121;
  letter-spacing: .02em;
  padding-top: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.section_title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

.back-to-top {
  cursor: pointer;
  opacity: 1;
  background-color: #9ca8e5;
  border: none;
  border-radius: 50%;
  padding: 5px;
  transition: opacity .5s;
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.back-to-top:hover {
  opacity: .6;
}

.back-to-top-icon {
  width: 30px;
  height: 30px;
  fill: #f5f4fa;
}

footer a {
  margin-top: 5px;
  text-decoration: underline;
  display: block;
}

@media screen and (min-width: 768px) {
  .section {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .section {
    padding-top: 60px;
  }
}

.nav__toggle {
  z-index: 30;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 50%;
  outline: 0;
  padding: 0;
  transition: background-color .15s linear;
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
}

.nav__toggle:hover, .nav__toggle:focus {
  background-color: #00000080;
}

.nav__menu {
  height: var(--screen-height);
  z-index: 30;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  padding-top: 35px;
  display: flex;
  position: relative;
}

.nav__item {
  opacity: 0;
  transition: all .3s cubic-bezier(0, .995, .99, 1) .3s;
}

.nav__item:nth-child(1) {
  transform: translateY(-40px);
}

.nav__item:nth-child(2) {
  transform: translateY(-80px);
}

.nav__item:nth-child(3) {
  transform: translateY(-120px);
}

.nav__item:nth-child(4) {
  transform: translateY(-160px);
}

.nav__item:nth-child(5) {
  transform: translateY(-200px);
}

.nav__link {
  color: #fff;
  text-align: center;
  letter-spacing: 1.2px;
  padding: .6rem;
  font-size: 20px;
  font-weight: 500;
  display: block;
}

.nav__link:hover, .nav__link:focus {
  background-color: #0003;
  outline: 0;
}

.menuicon {
  cursor: pointer;
  color: #fff;
  transition: all .3s cubic-bezier(.165, .84, .44, 1);
  display: block;
  transform: rotate(0deg);
}

.menuicon__bar, .menuicon__circle {
  fill: none;
  stroke: currentColor;
  stroke-width: 3px;
  stroke-linecap: round;
}

.menuicon__bar {
  transform-origin: 50%;
  transition: transform .25s ease-in-out;
  transform: rotate(0deg);
}

.menuicon__circle {
  stroke-dashoffset: 144.513px;
  stroke-dasharray: 144.513;
  transition: stroke-dashoffset .3s linear .1s;
}

.splash {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.splash:after {
  content: "";
  background-color: var(--splash-bg-color);
  width: 154vmin;
  height: 270vmin;
  transform-origin: 50%;
  will-change: transform;
  border-radius: 250px;
  transition: transform .5s cubic-bezier(.755, .05, .855, .06);
  display: block;
  position: absolute;
  top: -130vmin;
  left: -142vmin;
  transform: scale(0);
}

.nav:target > .splash:after, .nav--open > .splash:after {
  transform: scale(1);
}

.nav:target .menuicon, .nav--open .menuicon {
  color: #fff;
  transform: rotate(180deg);
}

.nav:target .menuicon__circle, .nav--open .menuicon__circle {
  stroke-dashoffset: 0;
}

.nav:target .menuicon__bar:nth-child(1), .nav:target .menuicon__bar:nth-child(4), .nav--open .menuicon__bar:nth-child(1), .nav--open .menuicon__bar:nth-child(4) {
  opacity: 0;
}

.nav:target .menuicon__bar:nth-child(2), .nav--open .menuicon__bar:nth-child(2) {
  transform: rotate(45deg);
}

.nav:target .menuicon__bar:nth-child(3), .nav--open .menuicon__bar:nth-child(3) {
  transform: rotate(-45deg);
}

.nav:target .nav__menu, .nav--open .nav__menu {
  visibility: visible;
}

.nav:target .nav__item, .nav--open .nav__item {
  opacity: 1;
  transform: translateY(0);
}

.viewport {
  background-color: #fff;
  background-color: var(--header-bg-color);
  margin: 0 auto;
}

.header {
  height: 60px;
}

@media screen and (min-width: 350px) {
  .splash:after {
    content: "";
    width: 153vmin;
    height: 270vmin;
    top: -130vmin;
    left: -142vmin;
  }
}

@media screen and (min-width: 450px) {
  .splash:after {
    content: "";
    width: 96vmax;
    height: 140vmax;
    top: -82vmax;
    left: -91vmax;
  }
}

@media screen and (min-width: 700px) {
  .splash:after {
    width: 101vmax;
    height: 140vmax;
    top: -85vmax;
    left: -96vmax;
  }

  .nav__link {
    padding: 1rem;
  }
}

@media screen and (min-width: 850px) {
  .splash:after {
    width: 106vmax;
    height: 140vmax;
    top: -65vmax;
    left: -101vmax;
  }
}

@media screen and (min-width: 1024px) {
  .nav, header, .viewport, .nav__menu {
    display: none;
  }
}

.header-desctop {
  display: none;
}

@media screen and (min-width: 1024px) {
  .header-desctop {
    background-color: var(--header-bg-color);
    height: 60px;
    display: flex;
  }

  .nav__menu-dectop {
    align-items: center;
    margin: 0 auto;
    display: flex;
  }

  .nav__menu-dectop li {
    color: #000;
    margin-right: 20px;
    font-size: 18px;
  }
}

.container {
  margin: 0 auto;
}

@media screen and (min-width: 320px) {
  .container {
    max-width: 320px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 75px;
    padding-right: 75px;
  }
}

.hero {
  background-image: url("hero.4d4489e8.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 400px 400px;
  padding: 270px 43px 20px 20px;
}

.hero_title {
  font-family: var(--main-font);
  color: var(--text-color);
  margin-left: -20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.logo_container {
  position: relative;
}

.logo {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -270px;
  left: -20px;
}

@media screen and (min-width: 450px) {
  .hero {
    background-size: 550px 500px;
    padding: 350px 43px 20px 20px;
  }

  .logo {
    width: 160px;
    height: 160px;
    top: -340px;
    left: -80px;
  }

  .hero_title {
    margin-left: -60px;
    font-size: 26px;
  }
}

@media screen and (min-width: 768px) {
  .hero {
    background-size: 800px 600px;
    padding: 445px 43px 45px 20px;
  }

  .hero_title {
    margin-left: -40px;
    font-size: 30px;
  }

  .logo {
    top: -440px;
    left: -30px;
  }
}

@media screen and (min-width: 1200px) {
  .hero {
    background-size: 1050px 750px;
    padding: 600px 43px 45px 20px;
  }

  .logo {
    width: 170px;
    height: 170px;
    top: -580px;
    left: 60px;
  }

  .hero_title {
    margin-left: 100px;
    font-size: 32px;
  }
}

@media screen and (min-width: 1500px) {
  .hero {
    background-size: 1200px 950px;
    padding: 700px 43px 45px 20px;
  }

  .logo {
    width: 180px;
    height: 180px;
    top: -680px;
    left: -15px;
  }
}

.le {
  background-color: var(--background-color);
  padding-bottom: 20px;
}

.info-section_le {
  margin: 20px 0;
}

.info-section_le span {
  font-weight: 500;
}

.info-section_course {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.info-section_contraindication-list {
  margin-bottom: 20px;
}

.info-section_contraindication-list li {
  margin-top: 10px;
}

.info-section_preparation-list {
  padding-bottom: 20px;
}

.info-section_preparation-list li {
  margin-top: 10px;
}

.info-section_contraindication__title, .info-section_preparation__title {
  margin-top: 20px;
  font-size: 20px;
}

.info {
  margin: 0 auto;
  display: block;
}

@media screen and (min-width: 768px) {
  .info-section_le, .info-section_le, .info-section_course, .info-section_contraindication, .info-section_preparation {
    padding: 0 70px;
  }
}

@media screen and (min-width: 1200px) {
  .info-section_le, .info-section_le, .info-section_course, .info-section_contraindication, .info-section_preparation {
    padding: 0 200px;
  }
}

:root {
  --main-color: #9ca8e5;
  --background-color: #f5f4fa;
  --main-font: "Montserrat", sans-serif;
  --text-color: #757575;
  --secondary-text-color: #212121;
  --screen-height: 600px;
  --header-bg-color: #292929;
  --splash-bg-color: #292929;
}

.about {
  background-color: var(--background-color);
}

.section-about {
  padding-bottom: 20px;
}

.about-section_service_text {
  margin-top: 20px;
}

.gallery {
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  scroll-snap-type: both mandatory;
  background-color: #fff;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin: 0 auto;
  padding: 30px;
  scroll-padding: 1rem;
  display: grid;
  overflow: scroll;
}

.gallery-list_item {
  scroll-snap-align: center;
  width: 300px;
  border-radius: 3px;
  font-size: 0;
  display: inline-block;
  position: relative;
}

.gallery-list_item img {
  width: 100%;
  height: auto;
}

.gallery-list_item .gallery-list_item__img {
  width: auto;
  height: 210px;
  object-fit: cover;
}

.transparent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.tab {
  margin-top: 20px;
}

.active {
  scroll-snap-type: unset;
}

.about-section_service {
  margin: 20px 0;
}

.about-section_prof {
  text-align: center;
}

.price-list {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  scroll-snap-type: both mandatory;
  background-color: var(--background-color);
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 auto;
  padding: 30px;
  scroll-padding: 1rem;
  display: grid;
  overflow: scroll;
}

.price-list li {
  scroll-snap-align: center;
  width: 300px;
  border-radius: 3px;
  font-size: 0;
  display: inline-block;
}

.price-list li img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .section-about {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 40px 0;
    display: grid;
  }

  .section-about img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .section-about_info {
    margin-left: 40px;
  }

  .tab {
    margin-top: 0;
  }

  .gallery {
    padding: 40px;
  }

  .gallery-list_item {
    width: 380px;
  }

  .gallery-list_item .gallery-list_item__img {
    width: auto;
    height: 265px;
  }

  .price-list {
    padding: 50px;
  }

  .price-list li {
    width: 420px;
  }
}

@media screen and (min-width: 1200px) {
  .price-list {
    padding: 100px;
  }

  .price-list li {
    width: 520px;
  }

  .gallery {
    padding: 100px;
  }

  .gallery-list_item {
    width: 500px;
  }

  .gallery-list_item img {
    width: 100%;
    height: auto;
  }

  .gallery-list_item .gallery-list_item__img {
    width: auto;
    height: 350px;
  }

  .transparent_desc {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .section-about {
    grid-template-columns: 2fr 2fr;
    padding: 80px 0;
  }

  .section-about_info {
    margin-left: 60px;
  }

  .about-section_service {
    margin-top: 40px;
  }
}

.section-salon_list {
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 0 auto;
  padding-bottom: 30px;
  display: grid;
}

.section-salon_list-plus {
  margin: 20px 0;
}

.section-salon_list-plus li {
  margin-top: 10px;
}

@media screen and (min-width: 1200px) {
  .section-salon_list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.contact {
  background-color: var(--background-color);
  padding-bottom: 20px;
}

.contact_adres-list li {
  align-items: center;
  margin-top: 15px;
  display: flex;
}

.contact_adres-list li svg {
  margin-right: 20px;
}

.contact_map {
  width: 280px;
  height: 300px;
  margin-top: 20px;
}

.contact_map iframe {
  width: 100%;
  height: 100%;
}

.logo-footer_container {
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.contact_name {
  text-align: center;
  margin-left: 15px;
  font-size: 20px;
  font-weight: 600;
}

.contact_adres__container {
  margin-left: 10px;
  font-size: 16px;
}

.footer {
  background-color: var(--header-bg-color);
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .contact_adres {
    margin-top: 20px;
    display: flex;
  }

  .contact_map {
    width: 600px;
    height: 400px;
    margin-top: 0;
    margin-left: 20px;
  }
}

.advantage {
  padding-bottom: 20px;
}

.advantage-list {
  margin: 0 auto;
}

.advantage-list li {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.advantage-list li svg {
  margin-right: 10px;
}

.advantage-list li:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .advantage-list {
    margin-left: 200px;
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .advantage-list {
    margin-left: 400px;
    padding-bottom: 60px;
  }
}

/*# sourceMappingURL=index.6dd09ac6.css.map */
