@function circumference($r){
  $pi: 3.141592653;
  @return 2*$pi*$r;
}

.nav {
  &__toggle {
    display:inline-block;
    position:absolute;
    z-index:30;
    padding:0;
    border:0;
    background:transparent;
    outline:0;
    right:15px;
    top:15px;
    cursor:pointer;
    border-radius:50%;
    transition:background-color .15s linear;
    
    &:hover,
    &:focus {
      background-color:rgba(0,0,0,.5);
    }
  }
  
  &__menu {
    display:flex;
    flex-direction:column;
    justify-content: center;
    padding-top: 35px;
    height:var(--screen-height);
    position:relative;
    z-index:30;
    visibility:hidden;
  }
  
  &__item {
    opacity:0;
    transition: all .3s cubic-bezier(0.000, 0.995, 0.990, 1.000) .3s;
  }
  @for $i from 1 through 5 {
    &__item:nth-child(#{$i}){
      transform:translateY(-40px * $i);
    }
  }
  
  &__link {
    color:white;
    display:block;
    text-align:center;
    letter-spacing: 1.2;
    font-size:20px;
    padding:0.6rem;
    font-weight: 500;
    
    &:hover,
    &:focus {
      outline:0;
      background-color:rgba(0,0,0,0.2);
    }
  }
}

.menuicon {
  display:block;
  cursor:pointer;
  color: white;
  
  transform:rotate(0deg);
  transition: .3s cubic-bezier(0.165, 0.840, 0.440, 1.000); 
  
  &__bar,
  &__circle {
    fill:none;
    stroke: currentColor;
    stroke-width:3;
    stroke-linecap:round;
  }
  &__bar {
    transform: rotate(0deg);
    transform-origin:50% 50%;
    transition: transform .25s ease-in-out;
  }
  &__circle {
    transition: stroke-dashoffset .3s linear .1s;
    stroke-dashoffset:circumference(23); // 23 is the <circle>'s radius
    stroke-dasharray:circumference(23);
  }
}

.splash {
  position:absolute;
  top:40px;
  right:40px;
  width: 1px;
  height: 1px;
  
  &::after {
    content:"";
    display:block;
    position:absolute;
    border-radius:250px;
    background-color:var(--splash-bg-color);
    width:154vmin;
    height:270vmin;
    top:-130vmin;
    left:-142vmin;
    
    transform: scale(0);
    transform-origin:50% 50%;
    transition: transform .5s cubic-bezier(0.755, 0.050, 0.855, 0.060);
    will-change:transform;
  }
}
.nav:target,
.nav--open {
  > .splash::after {
    transform:scale(1);
  }
  .menuicon {
    color:white;
    transform:rotate(180deg);
    
    &__circle {
      stroke-dashoffset:0;
    }
    &__bar:nth-child(1),
    &__bar:nth-child(4) {
      opacity:0;
    }
    &__bar:nth-child(2) {
      transform: rotate(45deg);
    }
    &__bar:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
  
  .nav {
    &__menu {
      visibility:visible;
    }
  
    &__item {
      opacity:1;
      transform:translateY(0);
    }
  }
}

.viewport {
  margin:0 auto;
  background-color:white;
  background-color:var(--header-bg-color);
}

.header {
  height: 60px;
}

@media screen and (min-width: 350px) {
  .splash {
    
    &::after {
      content:"";
      
      width:153vmin;
    height:270vmin;
    top:-130vmin;
    left:-142vmin;
    }
  }
}



@media screen and (min-width: 450px) {
  .splash {
    
    &::after {
      content:"";
      
      width:96vmax;
      height:140vmax;
      top:-82vmax;
      left:-91vmax;
    }
  }
}
@media screen and (min-width: 700px) {
  .splash {

    &::after {
      width:101vmax;
      height:140vmax;
      top:-85vmax;
      left:-96vmax;
      
    }
  }
  .nav {
    &__link{
      padding: 1rem;
    }
  }
 
}

@media screen and (min-width: 850px) {
  .splash {
    
    &::after {
      width:106vmax;
      height:140vmax;
      top:-65vmax;
      left:-101vmax;
    }
  }
}

@media screen and (min-width: 1024px) {
  .nav, header, .viewport, .nav__menu {
    display: none;
  }
    }


