.le {
    background-color: var(--background-color);
    padding-bottom: 20px;
}
.info-section_le {
    margin: 20px 0;
    & span {
        font-weight: 500;
    }
}

.info-section_course {
    margin: 20px 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
}
.info-section_contraindication-list {
    margin-bottom: 20px;
    & li {
        margin-top: 10px;
    }

}

.info-section_preparation-list {
    padding-bottom: 20px;
    & li {
        margin-top: 10px;
    }
}

.info-section_contraindication__title, .info-section_preparation__title {
    font-size: 20px;
    margin-top: 20px;
}

.info {
    display: block;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .info-section_le, .info-section_le, .info-section_course, .info-section_contraindication, .info-section_preparation {
       padding: 0 70px;
    } 
}

@media screen and (min-width: 1200px) {
    .info-section_le, .info-section_le, .info-section_course, .info-section_contraindication, .info-section_preparation {
       padding: 0 200px;
    } 
}