.about {
    background-color: var(--background-color);
}
.section-about  {
    padding-bottom: 20px;
}
.about-section_service_text {
    margin-top: 20px;
}
.gallery {
	padding: 30px;
    margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 10px;
	overflow: scroll;
	scroll-snap-type: both mandatory;
	scroll-padding: 1rem;
    background-color: #fff;
}
.gallery-list_item {
    scroll-snap-align: center;
    display: inline-block;
    border-radius: 3px;
    font-size: 0;
    width: 300px;
    position: relative;
    & img {
        width: 100%;
        height: auto;
    }
    & .gallery-list_item__img {
        width: auto;
        height: 210px;
        object-fit: cover;
    }
}

.transparent {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
.tab {
    margin-top: 20px;
}
.active {
	scroll-snap-type: unset;
}

.about-section_service {
    margin: 20px 0;
}

.about-section_prof {
    text-align: center;
}

.price-list {
	padding: 30px;
	display: grid;
    margin: 0 auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	overflow: scroll;
	scroll-snap-type: both mandatory;
	scroll-padding: 1rem;
    background-color: var(--background-color);
    & li {
        scroll-snap-align: center;
        display: inline-block;
        border-radius: 3px;
        font-size: 0;
        width: 300px;

        & img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}
  
@media screen and (min-width: 768px) {
    .section-about {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding: 40px 0;
        & img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    .section-about_info {
        margin-left: 40px;
    }
    .tab {
        margin-top: 0;
    }
    .gallery {
      padding: 40px;
    }
    .gallery-list_item {
        width: 380px;
        & .gallery-list_item__img {
            width: auto;
            height: 265px;
        }
    }
    
    .price-list {
        padding: 50px;
            & li {
             width: 420px;
        }
    }
}


@media screen and (min-width: 1200px) {
    .price-list {
        padding: 100px;
       
            & li {
             width: 520px;
        }
    }
    .gallery {
        padding: 100px;
        
    }
    .gallery-list_item {
        width: 500px; 
    
        & img {
            width: 100%;
            height: auto;
        }
        & .gallery-list_item__img {
            width: auto;
            height: 350px;
        }
    }
  
    .transparent_desc {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .section-about {
        padding: 80px 0;
        grid-template-columns: 2fr 2fr;

    }
    .section-about_info {
        margin-left: 60px;
    }
    .about-section_service {
        margin-top: 40px;
    } 
}
