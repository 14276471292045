.advantage {
    padding-bottom: 20px;
}

.advantage-list {
    margin: 0 auto;
    & li {
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.2;
        & svg {
            margin-right: 10px;
        }
    }
    & li:last-child {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 768px) {
    .advantage-list {
        margin-left: 200px;
        padding-bottom: 40px;
    }
}
@media screen and (min-width: 1200px) {
    .advantage-list {
        margin-left: 400px;
        padding-bottom: 60px;
    }
}