.hero {
  background-image: url(../images/hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 400px 400px;
  padding: 270px 43px 20px 20px;
}

.hero_title {
  font-family: var(--main-font);
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
  margin-left: -20px;
  line-height: 1.2;
  

  // width: 180px;
}
.logo_container {
  position: relative;
}
.logo {
width: 150px;
height: 150px;
position: absolute;
left: -20px;
top: -270px;
}

@media screen and (min-width: 450px) {
  .hero {
    background-size: 550px 500px;
    padding: 350px 43px 20px 20px;
  }
  .logo {
    width: 160px;
    height: 160px;
    left: -80px;
    top: -340px;
    }
    .hero_title {
      font-size: 26px;
      margin-left: -60px;
    }
}

@media screen and (min-width: 768px) {
  .hero {
    background-size: 800px 600px;
    padding: 445px 43px 45px 20px;
  }
  .hero_title {
    font-size: 30px;
    margin-left: -40px;
  }
  .logo {
    left: -30px;
    top: -440px;
    }
}
@media screen and (min-width: 1200px) {
  .hero {
    background-size: 1050px 750px;
    padding: 600px 43px 45px 20px;
  }
  .logo {
    left: 60px;
    top: -580px;
    width: 170px;
    height: 170px;
    }
    .hero_title {
      font-size: 32px;
      margin-left: 100px;
    }
}
@media screen and (min-width: 1500px) {
  .hero {
    background-size: 1200px 950px;
    padding: 700px 43px 45px 20px;
  }
  .logo {
    left: -15px;
    top: -680px;
    width: 180px;
    height: 180px;
    }

}