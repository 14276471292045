@import './common';
@import './header';
@import './header-desctop';
@import './container';
@import './hero';
@import './info';
@import './variables';
@import './about';
@import './salon';
@import './contact';
@import './advantage';

